import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";

export default class UniqueAuswahl extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.checks = super.set(".one-check input", true);
    this.init();
  }

  init() {
    this.events();
  }
  events() {
    this.checks.forEach((check) => {
      check.addEventListener("change", (e, checks = this.checks) => {
        if (e.target.checked) {
          const name = e.target.name;
          checks.forEach((el) => {
            if (el.name !== name) {
              el.checked = false;
            }
          });
        }
      });
    });
  }
  get() {
    var result = false;
    this.checks.forEach((check) => {
      if (check.checked === true) {
        result = check.name;
      }
    });
    return result;
  }
}

{
  /* 

    Standard Design

            <div class="checks-container" id="WieErreichbar">
                    <div class="one-check">
                      <div class="round">
                        <input
                          type="checkbox"
                          id="checkbox-phone"
                          class="mr-2"
                        />
                        <label for="checkbox-phone"></label>
                      </div>
                      <div class="check-text">Telefonisch</div>
                    </div>

                    <div class="one-check">
                      <div class="round">
                        <input
                          type="checkbox"
                          id="checkbox-whatsapp"
                          class="mr-2"
                        />
                        <label for="checkbox-whatsapp"></label>
                      </div>
                      <div class="check-text">WhatsApp</div>
                    </div>

                    <div class="one-check">
                      <div class="round">
                        <input type="checkbox" id="checkbox-sms" class="mr-2" />
                        <label for="checkbox-sms"></label>
                      </div>
                      <div class="check-text">SMS</div>
                    </div>
                  </div>


            import UniqueAuswahlt from "../Gadgets/UniqueAuswahlt";


            this.auswahl = new UniqueAuswahlt("#UniqueAuswahlt");

   */
}
