import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";
import Calendar from "../Gadgets/Calendar";

import jump from "jump.js";

import { checkValidEmail, checkValidPhone } from "../../js/Libraries/Form";
import Dropdwon from "../Gadgets/_Dropdown";
import UniqueAuswahl from "../Gadgets/_UniqueAuswahl";

export default class Anmeldung extends Standard {
  constructor() {
    super("#Anmeldung");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.http = new ZachiHttp();
    this.inputs = super.set('input[type="text"]', true);
    this.input_birthday = super.set(".birthday-input");
    this.anrede = super.set("#anrede");
    this.email = super.set("#email");
    this.phone = super.set("#phone");
    this.btn = super.set("#submitBtn");
    this.endpoint = super.setEndpoint("pages_api/anmeldung");
    this.anrede = new Dropdwon("#Anmeldung_dropdownAnrede");
    this.bDayRef_current = "";
    this.wieErreichbar = new UniqueAuswahl("#WieErreichbar");
    this.wunschTermin = new Calendar("#wunschTermin");
    this.wunschZeit = new Dropdwon("#wunschZeit");
    this.data = {
      ziel: false,
      wichtig: false,
      anrede: false,
      vorname: false,
      nachname: false,
      plz: false,
      city: false,
      email: false,
      birthday: false,
      phone: false,
      wieErreichbar: false,
      wunschTermin: false,
      wunschZeit: false,
    };
    this.firstBtn = super.set("#firstBtn");
    this.secondBtn = super.set("#secondBtn");
    this.thirdBtn = super.set("#thirdBtn");

    this.secondQ = super.set("#secondQ");
    this.thirdQ = super.set("#thirdQ");
    this.fourthQ = super.set("#fourthQ");

    this.firstError = super.set("#firstError");
    this.secondError = super.set("#secondError");
    this.thirdError = super.set("#thirdError");

    this.submitError = super.set("#submitError");

    this.init();
  }

  async init() {
    this.events();
  }

  checkEmail() {
    // check valid email
    const email = this.email.value.trim();
    if (!checkValidEmail(email)) {
      this.email.parentNode.classList.add("error");
      this.data[this.email.name] = false;
    } else {
      this.email.parentNode.classList.remove("error");
      this.data[this.email.name] = email;
    }
  }
  checkPhone() {
    // check valid email
    const phone = this.phone.value.trim();
    if (!checkValidPhone(phone)) {
      this.phone.parentNode.classList.add("error");
      this.data[this.phone.name] = false;
    } else {
      this.phone.parentNode.classList.remove("error");
      this.data[this.phone.name] = phone;
    }
  }

  checkPLZ() {
    const plz_el = super.set("#plz");
    const plz = plz_el.value.trim();
    let bool = true;
    function isNormalInteger(str) {
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    }
    if (plz === "") {
      bool = false;
      return false;
    }
    if (plz.length !== 5) {
      bool = false;
    }
    if (!isNormalInteger(plz)) {
      bool = false;
    }

    if (!bool) {
      this.data["plz"] = false;
      plz_el.parentNode.classList.add("error");
    } else {
      this.data["plz"] = plz;
      plz_el.parentNode.classList.remove("error");
      return;
    }
  }
  async purchase() {
    this.hl.toggle();
    this.data["anrede"] = this.anrede.check();
    this.data["wunschTermin"] = this.wunschTermin.check();
    this.data["wunschZeit"] = this.wunschZeit.check();
    this.checkBirthday();
    this.checkPhone();
    this.checkEmail();
    this.checkPLZ();

    this.data["wieErreichbar"] = this.wieErreichbar.get();

    this.inputs.forEach((each) => {
      if (each.value.trim() === "" && each.name !== "note") {
        each.parentNode.classList.add("error");
        this.data[each.name] = false;
      } else {
        each.parentNode.classList.remove("error");
        this.data[each.name] = each.value.trim();
      }
    });

    if (super.contains(this.data, false)) {
      this.submitError.classList.add("error");
      return this.hl.toggle();
    }

    this.submitError.classList.remove("error");

    if (this.checkLaw() === false) {
      return this.hl.toggle();
    }
    await this.send();
  }

  async send() {
    console.log(this.data);
    const res = await this.http.post(this.endpoint, this.data);
    console.log(res);
    await this.hl.wait();
    if (res.status === 201) {
      this.hl.toggle();
      window.location.href = res.redirect;
      return;
    } else {
      this.hl.message(res.error_text);
      return this.hl.toggle();
    }
  }
  listenBirthday() {
    function checkBirthdayValue(str, max) {
      if (str.charAt(0) !== "0" || str === "00") {
        let num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        if (
          num > parseInt(max.toString().charAt(0)) &&
          num.toString().length === 1
        ) {
          str = "0" + num;
        } else {
          str = num.toString();
        }
      }
      return str;
    }

    let value = this.input_birthday.value;
    let input = value;
    let currentYear = new Date().getFullYear();
    function intern_Bday(input) {
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
      let values = input.split(".").map(function (v) {
        return v.replace(/\D/g, "");
      });
      if (values[0]) values[0] = checkBirthdayValue(values[0], 31); // day check
      if (values[1]) values[1] = checkBirthdayValue(values[1], 12); // month check
      if (values[1]) values[1] = checkBirthdayValue(values[1], currentYear); // year check
      let output = values.map(function (v, i) {
        return v.length === 2 && i < 2 ? v + "." : v;
      });
      output = output.join("").substr(0, 10);
      return output;
    }
    let output = intern_Bday(input);
    if (
      output === this.bDayRef_current &&
      output.slice(-1) === "." &&
      this.bDayRef_current.slice(-1) === "."
    ) {
      output = intern_Bday(output.slice(0, -3));
    }
    this.bDayRef_current = output;
    this.input_birthday.value = output;
  }

  blurBirthday() {
    let currentYear = new Date().getFullYear();
    const year_helper = (str) => {
      if (str.length === 2) {
        let num = parseInt(str);
        if (num > 20) {
          num = num + 1900;
        } else {
          num = num + 2000;
        }
        str = num.toString();
      }

      return str;
    };
    const value = this.input_birthday.value;

    if (/\D\/$/.test(value)) value = value.substr(0, value.length - 3);
    let values = value.split(".").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (
      values[0] === undefined ||
      values[1] === undefined ||
      values[2] === undefined
    ) {
      this.bDayRef_current = "";
      return false;
    }
    values[2] = year_helper(values[2]);
    let output = values.map(function (v, i) {
      return v.length === 2 && i < 2 ? v + "." : v;
    });
    const year = parseInt(values[2]);
    if (year + 12 > currentYear || year < currentYear - 70) {
      this.hl.message("Bist du wirklich im Jahr " + year + " geboren?");
    }
    output = output.join("").substr(0, 10);
    this.bDayRef_current = output;
    this.input_birthday.value = output;
  }
  checkBirthday() {
    if (this.input_birthday.value.length !== 10) {
      this.input_birthday.classList.add("error");
      this.data["birthday"] = false;
    } else {
      this.input_birthday.classList.remove("error");
      this.data["birthday"] = this.input_birthday.value;
    }
  }
  checkLaw() {
    const law = super.set("#checkbox-law");
    const parent = law.parentNode.parentNode;
    if (law.checked) {
      parent.classList.remove("error");
    } else {
      parent.classList.add("error");
    }
    return law.checked;
  }

  firstMove() {
    var bool = true;
    this.inputs.forEach((each) => {
      if (each.name === "ziel" || each.name === "wichtig") {
        if (each.value.trim() === "" && each.name !== "note") {
          each.parentNode.classList.add("error");
          this.data[each.name] = false;
          bool = false;
        } else {
          each.parentNode.classList.remove("error");
          this.data[each.name] = each.value.trim();
        }
      }
    });

    if (bool === false) {
      this.firstError.classList.add("error");
    } else {
      this.firstError.classList.remove("error");
      this.secondQ.classList.remove("inactive");
      jump(this.secondQ, {
        duration: (distance) => Math.abs(distance) * 2,
      });
      this.firstBtn.classList.add("disable");
    }
  }
  secondMove() {
    this.data["wunschTermin"] = this.wunschTermin.check();
    this.data["wunschZeit"] = this.wunschZeit.check();
    this.data["wieErreichbar"] = this.wieErreichbar.get();

    if (
      this.data["wunschTermin"] === false ||
      this.data["wunschZeit"] === false
    ) {
      this.secondError.classList.add("error");
    } else {
      this.secondError.classList.remove("error");
      this.thirdQ.classList.remove("inactive");
      jump(this.thirdQ, {
        duration: (distance) => Math.abs(distance) * 2,
      });
      this.secondBtn.classList.add("disable");
    }
  }
  thirdMove() {
    this.data["anrede"] = this.anrede.check();
    this.data["wunschTermin"] = this.wunschTermin.check();
    this.data["wunschZeit"] = this.wunschZeit.check();
    this.checkPLZ();
    var bool = true;
    this.inputs.forEach((each) => {
      if (
        each.name === "anrede" ||
        each.name === "vorname" ||
        each.name === "nachname" ||
        each.name === "plz" ||
        each.name === "street" ||
        each.name === "city" ||
        each.name === "wichtig"
      ) {
        if (each.value.trim() === "" && each.name !== "note") {
          each.parentNode.classList.add("error");
          this.data[each.name] = false;
          bool = false;
        } else {
          each.parentNode.classList.remove("error");
          this.data[each.name] = each.value.trim();
        }
      }
    });

    if (bool === false) {
      this.thirdError.classList.add("error");
    } else {
      this.thirdError.classList.remove("error");
      this.fourthQ.classList.remove("inactive");
      jump(this.fourthQ, {
        duration: (distance) => Math.abs(distance) * 2,
      });
      this.thirdBtn.classList.add("disable");
    }
  }
  events() {
    this.inputs.forEach((each) => {
      each.addEventListener("focus", () => {
        each.parentNode.classList.remove("error");
        each.classList.remove("error");
      });
    });
    this.input_birthday.addEventListener("input", () => {
      this.listenBirthday();
    });
    this.input_birthday.addEventListener("blur", () => {
      this.blurBirthday();
    });
    this.firstBtn.addEventListener("click", () => this.firstMove());
    this.secondBtn.addEventListener("click", () => this.secondMove());
    this.thirdBtn.addEventListener("click", () => this.thirdMove());

    this.btn.addEventListener("click", () => this.purchase());
  }
}
