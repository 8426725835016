export default class Handlers {
  constructor() {
    this.create();
    this.overlay = document.querySelector(".loader-overlay");

    // MessageBox gleiches prinzip wie in BoxMessage

    this.createMessage();

    this.box = document.querySelector("#MessageInHandler");
    this.btn = this.box.querySelector(".close");
  }
  create() {
    if (document.querySelector(".loader-overlay") === null) {
      const loader = document.createElement("div");
      // loader.innerHTML = "<span></span><span></span><span></span>";
      loader.innerHTML = `      <div class='hollowLoader'>
      <div class='largeBox'></div>
      <div class='smallBox'></div>
    </div>`;
      loader.classList = "loader-overlay";
      document.body.insertAdjacentElement("afterbegin", loader);
    }
  }

  createMessage() {
    if (document.querySelector("#MessageInHandler") === null) {
      const box = document.createElement("div");
      box.id = "MessageInHandler";
      box.innerHTML = `
    <div class="content">
      <div class="close"><i class="fas fa-times"></i></div>
      <p class="pt-4">
          Hallo
      </p>
     <div class="btn_container">
        <button class="transparent">Zurück</button>  <button id="confirmBtn">Bestätigen</button> 
     </div>
    </div>`;

      document.body.insertAdjacentElement("afterbegin", box);
    }
  }
  toggle() {
    this.overlay.classList.toggle("active");
  }
  removeToggle() {
    this.overlay.classList.remove("active");
  }
  resetForm(form) {
    form.reset();
  }
  message(
    msg = "Es gibt zurzeit leider ein Problem mit unserem Server. Versuche es später noch einmal!",
    confirm = false
  ) {
    this.box.classList.add("active");
    this.box.querySelector("p").innerHTML = msg;
    function closeBox(self, resolve = null) {
      self.box.classList.remove("active");
      if (resolve !== null) {
        resolve(false);
      }
    }
    function outsideClick(e, self, resolve = null) {
      if (e.target == self.box) {
        self.box.classList.remove("active");
        if (resolve !== null) {
          resolve(false);
        }
      }
    }

    function confirmFct(self, resolve) {
      self.box.classList.remove("active");
      resolve(true);
    }
    function declineFct(self, resolve) {
      self.box.classList.remove("active");
      resolve(false);
    }
    if (confirm === false) {
      this.box.querySelector(".btn_container").style.display = "none";
      this.btn.addEventListener("click", () => closeBox(this));
      document.addEventListener("click", (e) => outsideClick(e, this));
    }

    if (confirm == true) {
      return new Promise((resolve, reject) => {
        this.box.querySelector(".btn_container").style.display = "flex";
        this.box
          .querySelector(".transparent")
          .addEventListener("click", () => declineFct(this, resolve));
        this.box
          .querySelector("#confirmBtn")
          .addEventListener("click", () => confirmFct(this, resolve));
        this.btn.addEventListener("click", () => closeBox(this, resolve));
        document.addEventListener("click", (e) =>
          outsideClick(e, this, resolve)
        );
      });
    }
  }
  wait(time = 2000) {
    return new Promise((resolve, reject) => {
      if (false) {
        reject("Looser");
      }
      setTimeout(() => {
        resolve();
      }, time);
    });
  }
  elementInViewportAnyPart(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset
    );
  }

  widthOfScreen() {
    å;
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
  async toggle_wait(time = 1000) {
    this.toggle();
    await this.wait(time);
    this.toggle();
  }
}
