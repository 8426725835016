import Standard from "../../js/Libraries/Standard";

export default class FAQHome extends Standard {
  constructor() {
    super("#FAQHome");
    if (!super.exist()) return;
    this.jobs = super.set(".accordion-container", true);
    this.frage = super.getParam("frage");
    this.hash = window.location.hash;
    if (this.hash === "") {
      this.hash = null;
    } else {
      this.hash = this.hash.substring(1).toLowerCase();
    }
    this.jobs.forEach((job) => {
      new OneAccordin(job, this.hash);
    });
  }
}

class OneAccordin {
  constructor(el, hash) {
    this.el = el;
    this.hash = hash;
    this.btn = el.querySelector(".accordion-btn");
    this.btn.setAttribute("state", "closed");
    this.open_btn = el.querySelector("#open-btn");
    this.close_btn = el.querySelector("#close-btn");
    this.content = el.querySelector(".accordion-content-container");

    if (this.open_btn !== undefined && this.close_btn !== undefined) {
      this.btn.addEventListener("click", () => {
        if (this.btn.getAttribute("state") === "closed") {
          this.content.style.height = `${this.content.scrollHeight + 80}px`;
          this.content.classList.add("active");
          this.open_btn.classList.remove("open");
          this.close_btn.classList.remove("closed");
          this.open_btn.classList.add("closed");
          this.close_btn.classList.add("open");
          this.btn.setAttribute("state", "open");
        } else {
          this.content.style.height = `0`;
          this.content.classList.remove("active");
          this.open_btn.classList.remove("closed");
          this.close_btn.classList.remove("open");
          this.open_btn.classList.add("open");
          this.close_btn.classList.add("closed");
          this.btn.setAttribute("state", "closed");
        }
      });
    }
    window.addEventListener("resize", () => {
      this.content.style.height = `0`;
      this.content.classList.remove("active");
      this.open_btn.classList.remove("closed");
      this.close_btn.classList.remove("open");
      this.open_btn.classList.add("open");
      this.close_btn.classList.add("closed");
      this.btn.setAttribute("state", "closed");
    });

    if (this.el.id.toLowerCase() === this.hash) {
      setTimeout(() => {
        this.content.style.height = `${this.content.scrollHeight + 80}px`;
        this.content.classList.add("active");
        this.open_btn.classList.remove("open");
        this.close_btn.classList.remove("closed");
        this.open_btn.classList.add("closed");
        this.close_btn.classList.add("open");
      }, 1000);
    }
  }
}
