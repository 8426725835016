import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";
import Calendar from "../Gadgets/Calendar";

import jump from "jump.js";

import { checkValidEmail, checkValidPhone } from "../../js/Libraries/Form";
import Dropdwon from "../Gadgets/_Dropdown";

export default class Anmeldung2 extends Standard {
  constructor() {
    super("#Anmeldung2");
    if (!super.exist()) return;
    this.startIndex = 0;
    this.dev = false;
    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.inputs = super.set('input[type="text"]', true);
    this.next2 = super.set("#searchNext2");
    this.back2 = super.set("#searchBack2");
    this.next3 = super.set("#searchNext3");
    this.back3 = super.set("#searchBack3");

    this.next5 = super.set("#searchNext5");
    this.back5 = super.set("#searchBack5");
    this.fragen = super.set(".animation", true);

    this.endpoint = super.setEndpoint("pages_api/anmeldung_2");
    this.endpoint_vor = super.setEndpoint("pages_api/vor_anmeldung_2");

    this.themeImages = super.set(".themeImages", true);

    this.slide_1_drops_title = super.set("#slide_1_drops_title");
    this.slide_1_drops = super.set("#slide_1_drops");
    this.slide1_frage_1 = super.set("#slide1_frage_1");
    this.slide1_frage_1_text = super.set("#slide1_frage_1_text");
    this.slide1_frage_2 = super.set("#slide1_frage_2");
    this.slide1_frage_2_text = super.set("#slide1_frage_2_text");
    this.slide1_frage_1_input = super.set("#slide1_frage_1_input");
    this.slide1_frage_2_input = super.set("#slide1_frage_2_input");
    this.wunschTermin = new Calendar("#wunschTermin");
    this.wunschZeit = new Dropdwon("#wunschZeit");
    this.email = super.set("#email");
    this.phone = super.set("#phone");
    this.vorname = super.set("#vorname");
    this.nachname = super.set("#nachname");
    this.firstTimeFrage2 = true;
    this.result = {
      thema: null,
      dropFrage2: null,
      slide_2_frage_1: "",
      slide_2_frage_2: "",
      vorname: false,
      nachname: false,
      email: false,
      phone: false,
      wunschTermin: false,
      wunschZeit: false,
      nachricht: false,
    };

    this.events();
    this.read();
    this.init();
  }

  read() {
    const x = this.container.getAttribute("data-fragen2");
    if (this.dev === false) {
      this.fragen2 = JSON.parse(x);
    } else {
      this.fragen2 = null;
    }
  }

  init() {
    this.fragen.forEach((each, index) => {
      if (index === this.startIndex) {
        each.style.transform = "translateX(-50%)";
        each.style.opacity = "1";
        each.style.pointerEvents = "all";
      } else {
        each.style.transform = "translateX(50%)";
        each.style.opacity = "0";
        each.style.pointerEvents = "none";
      }
      each.style.transition = "transform 1.2s ,opacity 0.8s";
    });
  }
  setToLeft(obj) {
    obj.style.transform = "translateX(-150%)";
    obj.style.opacity = "0";
    obj.style.pointerEvents = "none";
  }
  setToRight(obj) {
    obj.style.transform = "translateX(50%)";
    obj.style.opacity = "0";
    obj.style.pointerEvents = "none";
  }
  setActive(obj) {
    obj.style.transform = "translateX(-50%)";
    obj.style.opacity = "1";
    obj.style.pointerEvents = "all";
  }

  toNext(current) {
    this.setToLeft(this.fragen[current]);
    this.setActive(this.fragen[current + 1]);
  }
  toBack(current) {
    this.setToRight(this.fragen[current]);
    this.setActive(this.fragen[current - 1]);
  }

  async scrollTo(goal) {
    await this.hl.wait(800);
    jump(goal, {
      duration: (distance) => Math.abs(distance) * 2,
      offset: -25,
    });
  }

  setFragen2(name) {
    this.slide_1_drops_title.innerText = this.fragen2[name]["dropTitle"];
    this.slide_1_drops.innerHTML = "";
    this.fragen2[name]["drops"].forEach((each) => {
      this.slide_1_drops.innerHTML += "<li>" + each + "</li>";
    });
    if (this.firstTimeFrage2 === true) {
      this.dropFrage2 = new Dropdwon("#dropFrage2");
      this.firstTimeFrage2 = false;
    } else {
      this.dropFrage2.setNewList();
    }

    const frage1 = this.fragen2[name]["frage1"];
    if (frage1 === false) {
      this.slide1_frage_1.style.display = "none";
    } else {
      this.slide1_frage_1_text.innerText = frage1;
    }
    const frage2 = this.fragen2[name]["frage2"];
    if (frage2 === false) {
      this.slide1_frage_2.style.display = "none";
    } else {
      this.slide1_frage_2_text.innerText = frage2;
    }
  }
  async nextFct0(el, name) {
    this.themeImages.forEach((each) => each.classList.remove("choosen"));
    el.classList.add("choosen");

    this.setFragen2(name);
    await this.hl.wait(400);
    this.result["thema"] = name;
    this.toNext(0);
    await this.scrollTo("#fragenSlide-1");
  }

  async nextFct1() {
    this.result["dropFrage2"] = this.dropFrage2.check(false);
    this.result["slide_2_frage_1"] = this.slide1_frage_1_input.value.trim();
    this.result["slide_2_frage_2"] = this.slide1_frage_2_input.value.trim();
    console.log(this.result);
    this.toNext(1);
    await this.scrollTo("#fragenSlide-2");
  }
  async backFct1() {
    this.toBack(1);
    await this.scrollTo("#fragenSlide-0");
  }
  async nextFct2() {
    var bool = true;
    this.checkPhone();
    this.checkEmail();
    this.result[this.vorname.name] = this.vorname.value.trim();
    this.result[this.nachname.name] = this.nachname.value.trim();
    if (
      this.result[this.vorname.name] === false ||
      this.result[this.vorname.name] === ""
    ) {
      this.vorname.classList.add("error");
      bool = false;
    } else {
      this.vorname.classList.remove("error");
    }

    if (
      this.result[this.nachname.name] === false ||
      this.result[this.nachname.name] === ""
    ) {
      this.nachname.classList.add("error");
      bool = false;
    } else {
      this.nachname.classList.remove("error");
    }

    if (this.result[this.phone.name] === false) {
      bool = false;
    }
    console.log(this.result);
    if (bool === false) {
      this.showError("#errorSlide3");
    } else {
      this.removeError("#errorSlide3");
      this.vor_send();
      this.toNext(2);
      await this.scrollTo("#fragenSlide-3");
    }
  }
  async backFct2() {
    this.toBack(2);
    await this.scrollTo("#fragenSlide-2");
  }

  async nextFct4() {
    this.result["wunschTermin"] = this.wunschTermin.check(false);
    this.result["wunschZeit"] = this.wunschZeit.check(false);
    this.result["nachricht"] = super.set("#message").value;
    if (this.checkLaw() === false) {
      return;
    }
    await this.send();
  }
  async backFct4() {
    this.toBack(3);
    await this.scrollTo("#fragenSlide-2");
  }

  showError(currentFrageId) {
    this.container.querySelector(currentFrageId).classList.add("error");
  }
  removeError(currentFrageId) {
    this.container.querySelector(currentFrageId).classList.remove("error");
  }
  checkEmail() {
    const email = this.email.value.trim();
    if (!checkValidEmail(email)) {
      // this.email.parentNode.classList.add("error");
      this.result[this.email.name] = false;
    } else {
      // this.email.parentNode.classList.remove("error");
      this.result[this.email.name] = email;
    }
    this.result[this.email.name] = email;
  }
  checkPhone() {
    const phone = this.phone.value.trim();
    if (!checkValidPhone(phone)) {
      this.phone.parentNode.classList.add("error");
      this.result[this.phone.name] = false;
    } else {
      this.phone.parentNode.classList.remove("error");
      this.result[this.phone.name] = phone;
    }
  }
  checkLaw() {
    const law = super.set("#checkbox-law");
    const parent = law.parentNode.parentNode;
    if (law.checked) {
      parent.classList.remove("error");
    } else {
      parent.classList.add("error");
    }
    return law.checked;
  }
  async vor_send() {
    console.log(this.result);
    const res = await this.http.post(this.endpoint_vor, this.result);
    console.log(res);
  }

  async send() {
    this.hl.toggle();
    console.log(this.result);
    const res = await this.http.post(this.endpoint, this.result);
    console.log(res);
    await this.hl.wait();
    if (res.status === 201) {
      this.hl.toggle();
      window.location.href = res.redirect;
      return;
    } else {
      this.hl.message(res.error_text);
      return this.hl.toggle();
    }
  }
  events() {
    this.next2.addEventListener("click", () => this.nextFct1());
    this.back2.addEventListener("click", () => this.backFct1());

    this.next3.addEventListener("click", () => this.nextFct2());
    this.back3.addEventListener("click", () => this.backFct2());
    //

    this.next5.addEventListener("click", () => this.nextFct4());
    this.back5.addEventListener("click", () => this.backFct4());

    this.themeImages.forEach((el) => {
      const name = el.getAttribute("data-name");

      el.addEventListener("click", () => this.nextFct0(el, name));
    });

    this.inputs.forEach((each) => {
      each.addEventListener("focus", () => {
        each.parentNode.classList.remove("error");
        each.classList.remove("error");
      });
    });
  }
}
