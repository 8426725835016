import Back2Top from "./Back2Top.js";
import Navbar from "./Navbar.js";
import GET_Message from "./_GET_Message.js";

new Navbar();
new Back2Top("#Back2Top");

const link_to = document.querySelectorAll(".link_to");
link_to.forEach((el) => {
  el.addEventListener("click", () => {
    let link = el.getAttribute("link_to");
    if (link !== null) {
      window.location.href = link;
    }
  });
});

const link_to_blanket = document.querySelectorAll(".link_to_blanket");
link_to_blanket.forEach((el) => {
  el.addEventListener("click", () => {
    let link = el.getAttribute("link_to");
    if (link !== null) {
      window.open(link, "_blank");
    }
  });
});

new GET_Message();
