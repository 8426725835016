import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";

export default class TheorieKlassen extends Standard {
  constructor() {
    super("#TheorieKlassen");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.boxes = super.set(".one-col", true);
    this.init();
  }

  init() {
    this.boxes.forEach((el) => {
      const btn = el.querySelector("button");
      const content = el.querySelector(".text-for-box");
      if (content !== null) {
        btn.addEventListener("click", () => {
          this.hl.message(content.innerHTML);
        });
      }
    });
  }

  events() {}
}
