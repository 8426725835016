import "../../js/Gadgets/_glider_extern";
// Glider Configuration
if (document.querySelector(".glider") !== null) {
  new Glider(document.querySelector(".glider"), {
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    dots: ".dots",
    responsive: [
      {
        // If Screen Size More than 768px
        breakpoint: 320,
        settings: {
          duration: 1,
          arrows: {
            prev: ".glider-prev",
            next: ".glider-next",
          },
        },
      },
      {
        // If Screen Size More than 768px
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          duration: 1,
          arrows: {
            prev: ".glider-prev",
            next: ".glider-next",
          },
        },
      },
      {
        // If Screen Size More than 768px
        breakpoint: 640,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          duration: 1,
          arrows: {
            prev: ".glider-prev",
            next: ".glider-next",
          },
        },
      },
      {
        // If Screen Size More than 768px
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          duration: 1,
          arrows: {
            prev: ".glider-prev",
            next: ".glider-next",
          },
        },
      },
      {
        // If Screen Size More than 768px
        breakpoint: 920,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          duration: 1,
          arrows: {
            prev: ".glider-prev",
            next: ".glider-next",
          },
        },
      },
      {
        // If Screen Size More than 1024px
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          duration: 1,
          arrows: {
            prev: ".glider-prev",
            next: ".glider-next",
          },
        },
      },
    ],
  });
}
