import Standard from "../../js/Libraries/Standard";

/* 
  -fehler bei textupload in backend, was angezeigt???
  - 'data feld muss ausgefüllt sein, bisher ohne benarchitgn...
  - textarea text richtig abstimmen....
*/
export default class Navbar extends Standard {
  constructor() {
    super("#navbar");
    if (!super.exist()) return;

    this.burger = super.set(".z-burger");
    this.pages = super.set(".z-nav-pages");
    this.navLinks = super.set(".z-nav-page", true);
    this.speed = 0.65;

    this.img = super.set(".z-logo-container img");

    this.events();
  }

  events() {
    this.burger.addEventListener("click", () => {
      if (this.pages.dummy) {
        this.pages.style.animation = `navSlideOut ${this.speed}s ease backwards`;
        this.pages.dummy = "";
      } else {
        this.pages.style.animation = `navSlideIn ${this.speed}s ease forwards`;
        this.pages.dummy = "x";
      }
      this.burger.classList.toggle("z-toggle");
      this.navLinks.forEach((link, index) => {
        var speed_pro = index / 8 + this.speed - 0.35;
        if (link.style.animation) {
          link.style.animation = "";
        } else {
          link.style.animation = `navLinkFade 0.5s ease forwards ${speed_pro}s`;
        }
      });
    });

    if (this.img !== null) {
      this.img.addEventListener("click", () => {
        window.location.href = "/";
      });
    }
  }
}
