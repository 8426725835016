export default class Times {
  constructor() {
    this.full_days = [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ];
  }
  parseDate(input, time = false) {
    var parts = input.match(/(\d+)/g);
    this.parseTime('8:00');
    var date = new Date(parts[2], parts[1] - 1, parts[0]);
    if (time) {
      var parts = this.parseTime(time);
      date.setHours(parts[0], parts[1]);
      return date;
    } else {
      return date;
    }
  }

  get_weekday(date_german) {
    const date = this.parseDate(date_german);
    return this.full_days[+date.getDay()];
  }
  parseTime(input) {
    var parts = input.match(/(\d+)/g);
    return parts;
  }

  toGermanDate(date) {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }
}
