import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";

export default class Animations extends Standard {
  constructor() {
    super("body");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.animationZoomImg = super.set(".animation-zoom-img", true);
    this.init();
  }

  init() {
    this.secondThemeImage();
  }

  async secondThemeImage() {
    await this.hl.wait(800);
    this.animationZoomImg.forEach((element) => {
      element.classList.remove("animation-zoom-img");
    });
  }
}
