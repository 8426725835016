import Standard from "../../js/Libraries/Standard";
import Times from "../../js/Libraries/Times";
export default class Calendar extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.choosenDay = super.set("#date");
    this.termin = super.set(".termin");
    this.calender = super.set(".pop-up-calendar");
    this.nextBtn = this.calender.querySelector(".next");
    this.prevBtn = this.calender.querySelector(".prev");
    this.times = new Times();
    this.dt = new Date();
    this.months = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ];
    this.days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    this.full_days = [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ];
    this.renderDate();
    this.nextBtn.addEventListener("click", () => this.moveDate("next"));
    this.prevBtn.addEventListener("click", () => this.moveDate("prev"));

    this.daysOfMonth = [];
    this.result = false;
    this.init();
  }

  get_weekday(date) {
    const termin = this.times.parseDate(date);
    return this.full_days[+termin.getDay()];
  }

  dateToString(date) {
    return `${this.zeroToInt(date.getDate())}.${this.zeroToInt(
      date.getMonth() + 1
    )}.${date.getFullYear()}`;
  }
  getDateOfClick(day, self) {
    const termin = new Date(self.dt.getFullYear(), self.dt.getMonth(), day);

    // const now = new Date();
    // if (termin > now) {
    this.setDate(this.dateToString(termin), this.full_days[+termin.getDay()]);
    // this.choosenDay.innerText = this.dateToString(termin);
    // this.choosenWeekday.innerText = this.full_days[+termin.getDay()];
    this.calender.classList.remove("active");
    // }
    // verarbite zu meinem format...
  }

  renderDate() {
    this.calender.querySelector("#month").innerHTML =
      this.months[this.dt.getMonth()];
    this.calender.querySelector("#date_str").innerHTML =
      this.datetoGermanString();

    this.dt.setDate(1);
    // an welchem wochentag ist 1 des Monats
    var day = this.dt.getDay();
    var today = new Date();
    // last day of current month
    var endDate = new Date(
      this.dt.getFullYear(),
      this.dt.getMonth() + 1,
      0
    ).getDate();
    // last day of month before 31,30,29,28 oder
    var prevDate = new Date(
      this.dt.getFullYear(),
      this.dt.getMonth(),
      0
    ).getDate();
    // wenn auf sonntag 1 fällt
    if (day === 0) {
      day = 7;
    }
    var cells = "";
    for (let x = day; x > 1; x--) {
      cells += "<div class='prev_date'>" + (prevDate - x + 2) + "</div>";
    }
    for (let i = 1; i <= endDate; i++) {
      if (
        i == today.getDate() &&
        this.dt.getMonth() == today.getMonth() &&
        this.dt.getFullYear() == today.getFullYear()
      ) {
        cells += "<div class='day today'>" + i + "</div>";
      } else {
        cells += '<div class="day">' + i + "</div>";
      }
    }
    this.calender.querySelectorAll(".days")[0].innerHTML = cells;
    this.setListenersToDays();
  }
  datetoGermanString() {
    return `${this.days[this.dt.getDay()]} ${this.zeroToInt(
      this.dt.getDate()
    )}.${this.zeroToInt(this.dt.getMonth() + 1)}.${this.dt.getFullYear()}`;
  }
  zeroToInt(x) {
    var out = String(x);
    if (out.length === 1) {
      out = "0" + out;
    }
    return out;
  }

  moveDate(para) {
    if (para == "prev") {
      this.dt.setMonth(this.dt.getMonth() - 1);
    } else if (para == "next") {
      this.dt.setMonth(this.dt.getMonth() + 1);
    }
    this.renderDate();
  }

  setListenersToDays() {
    //   only set if in future...
    this.daysOfMonth = this.calender.querySelectorAll(".day");
    this.daysOfMonth.forEach((each) =>
      each.addEventListener("click", (e) =>
        this.getDateOfClick(each.innerText, this)
      )
    );
  }

  init() {
    this.termin.addEventListener("click", () => {
      this.calender.classList.toggle("active");
      this.dt = new Date();
      this.renderDate();
    });
  }
  getDate() {
    const text = this.choosenDay.innerText;

    const weekday = 0;
    if (text === "Datum") {
      return false;
    } else {
      return [text, weekday];
    }
  }
  setDate(day, weekday) {
    this.choosenDay.innerText = weekday + " " + day;
    this.result = day;
    this.removeError();
  }
  addError() {
    this.termin.classList.add("error");
  }
  removeError() {
    this.termin.classList.remove("error");
  }
  get() {
    return this.result;
  }

  check(exit = true) {
    if (this.get() === false) {
      if (exit === true) {
        this.addError();
        return false;
      } else {
        return false;
      }
    } else {
      this.removeError();
      return this.get();
    }
  }
}

/*


          <div class="calendar-container" id="Calendar">
            <div class="termin">
              <span class="choosen-day" id="date">Datum</span>
              <i class="fas fa-caret-down"></i>
            </div>
            <div class="pop-up-calendar">
              <div class="month">
                <div class="prev">
                  <i class="fas fa-chevron-left"></i>
                </div>
                <div>
                  <h2 id="month"></h2>
                  <p id="date_str"></p>
                </div>
                <div class="next">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
              <div class="weekdays">
                <div>Mo</div>
                <div>Di</div>
                <div>Mi</div>
                <div>Do</div>
                <div>Fr</div>
                <div>Sa</div>
                <div>So</div>
              </div>
              <div class="days"></div>
            </div>
          </div>



   In Class

    import Calendar from "../Gadgets/Calendar";


     this.date = new Calendar("#Calendar");

*/
