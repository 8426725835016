import jump from "jump.js";
import FAQHome from "./FaqHome";
import TheorieKlassen from "./_TheorieKlassen";
import ThreeBoxes from "./_ThreeBoxes";

import "./_HomeGlider";
import Animations from "./_Animations";

const footer_year = document.querySelector("#footer_year");
if (footer_year !== null) {
  var date = new Date();
  footer_year.innerText = date.getFullYear();
}

const faqBtnFooter = document.querySelector("#faqBtnFooter");
if (faqBtnFooter !== null) {
  faqBtnFooter.addEventListener("click", (e) => {
    e.preventDefault();
    const FAQHome = document.querySelector("#FAQHome");
    if (FAQHome !== null) {
      jump(FAQHome, {
        duration: (distance) => Math.abs(distance),
      });
    } else {
      window.location.href = faqBtnFooter.href;
    }
  });
}
const faqBtnNavbar = document.querySelector("#faqBtnNavbar");
if (faqBtnNavbar !== null) {
  faqBtnNavbar.addEventListener("click", (e) => {
    e.preventDefault();
    const FAQHome = document.querySelector("#FAQHome");
    if (FAQHome !== null) {
      jump(FAQHome, {
        duration: (distance) => Math.abs(distance),
      });
    } else {
      window.location.href = faqBtnNavbar.href;
    }
  });
}

new FAQHome();
new ThreeBoxes();
new TheorieKlassen();

new Animations();
