import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";

export default class ThreeBoxes extends Standard {
  constructor() {
    super("#ThreeBoxes");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.boxes = super.set(".box", true);
    this.init();
  }

  async init() {
    if (this.hl.elementInViewportAnyPart(this.container)) {
      await this.hl.wait(2000);
      this.animation();
    } else {
      this.events();
    }
  }

  async animation() {
    this.boxes[0].classList.remove("animation-start");
    await this.hl.wait(500);
    this.boxes[1].classList.remove("animation-start");
    await this.hl.wait(500);
    this.boxes[2].classList.remove("animation-start");
  }

  events() {
    document.addEventListener("scroll", async () => {
      if (this.hl.elementInViewportAnyPart(this.container)) {
        await this.hl.wait(1000);
        this.animation();
      } else {
        // this.boxes[0].classList.add("animation-start");
        // this.boxes[1].classList.add("animation-start");
        // this.boxes[2].classList.add("animation-start");
      }
    });
  }
}
