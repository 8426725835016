import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";

export default class Dropdwon extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.id = id;
    this.btn = super.set(".dropdown-btn");
    if (this.btn === null) {
      console.log("error " + id);
      return;
    }

    this.result = false;
    this.init();
  }

  init() {
    this.active();
  }
  active() {
    this.btn.addEventListener("click", () => {
      this.container.classList.toggle("active");
      this.btn.classList.remove("error");
    });
    this.setNewList();
    function outsideClick(e, container, id) {
      if (!e.target.matches(id + " .dropdown-btn")) {
        container.classList.remove("active");
      }
    }
    document.addEventListener("click", (e) =>
      outsideClick(e, this.container, this.id)
    );
  }
  get() {
    return this.result;
  }
  error(bool = null) {
    if (bool === null) {
      this.btn.classList.toggle("error");
    } else if (bool === false) {
      this.btn.classList.remove("error");
    } else if (bool === true) {
      this.btn.classList.add("error");
    }
  }
  check(exit = true) {
    if (this.get() === false) {
      if (exit === true) {
        this.error(true);
        return false;
      } else {
        return false;
      }
    } else {
      this.error(false);
      return this.get();
    }
  }
  setNewList() {
    this.btn.innerHTML = `------<i class="fas fa-caret-down"></i>`;
    this.result = false;
    this.lis = super.set("li", true);
    this.lis.forEach((each) => {
      each.addEventListener("click", () => {
        this.btn.innerHTML =
          each.innerText + '<i class="fas fa-caret-down"></i>';
        this.container.classList.remove("active");
        this.result = each.innerText;
      });
    });
  }
}

/* 

    Standard Design

         <div id="Anmeldung_dropdownAnrede" class="dropdown">
                  <div class="dropdown-btn">
                    ------<i class="fas fa-caret-down"></i>
                  </div>
                  <ul>
                    <li>Frau</li>
                    <li>Herr</li>
                    <li>Divers</li>
                  </ul>
        </div>

            import Dropdown from "../Gadgets/Dropdown";


            this.drop = new Dropdown("#Dropdown");

             this.data["anrede"] =this.anrede.check();
   */
